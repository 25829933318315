$(document).on('ready', function() {
    $('[data-gallery] [data-gallery-item]').on('mouseover', function() {
        var $this = $(this);
        var $gallery = $this.closest('[data-gallery]');
        if ($gallery.attr('data-gallery-item-id') == $this.attr('data-gallery-item-id')) {
            return;
        }

        $target = $('#'+$gallery.attr('data-gallery-target'));

        if (!$target) {
            return;
        }


        $gallery.attr('data-gallery-item-id', $this.attr('data-gallery-item-id'));
        var $picture = $this;

        if ($this.filter('picture').length <= 0) {
            $picture = $this.find('picture');
        }

        var $pictureTarget = $target;
        if ($target.filter('picture').length <= 0) {
            $pictureTarget = $target.find('picture');
        }

        $pictureTarget.find('source').attr('srcset', $picture.attr('data-path-big-webp'));
        $pictureTarget.find('img').attr('src', $picture.attr('data-path-big'));

        $gallery.find('[data-gallery-item][data-gallery-item-current]').removeAttr('data-gallery-item-current');
        $this.attr('data-gallery-item-current', 1);
    });

    $('[data-gallery-control]').on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        var $galleryTarget = $('#'+$this.attr('data-gallery-target'));

        var $gallery = $('[data-gallery][data-gallery-target="'+$this.attr('data-gallery-target')+'"]');

        var $pictureTarget = $galleryTarget;
        if ($galleryTarget.filter('picture').length <= 0) {
            $pictureTarget = $galleryTarget.find('picture');
        }

        var $galleryItems = $gallery.find('[data-gallery-item]');
        var $galleryItemCurrent = $galleryItems.filter('[data-gallery-item-current]');

        if ($this.hasAttr('data-gallery-control-left')) {
            $picture = $galleryItemCurrent.prev();
            if ($picture.length <= 0) {
                $picture = $galleryItems.last();
            }
        } else {
            $picture = $galleryItemCurrent.next();
            if ($picture.length <= 0) {
                $picture = $galleryItems.first();
            }
        }

        $pictureTarget.find('source').attr('srcset', $picture.attr('data-path-big-webp'));
        $pictureTarget.find('img').attr('src', $picture.attr('data-path-big'));

        $gallery.find('[data-gallery-item][data-gallery-item-current]').removeAttr('data-gallery-item-current');
        $nextGalleryItemCurrent = $picture;
        if (!$nextGalleryItemCurrent.is('[data-gallery-item]')) {
            $nextGalleryItemCurrent.closest('[data-gallery-item]');
        }

        $nextGalleryItemCurrent.attr('data-gallery-item-current', 1);

        return false;
    });
});

$(function() {
    var NsQuotation = {
        loadForm: function(container, productId, onlySetData) {
            // var $this = $(this);
            // var container = $this.parents('[data-form-collection="item"]');

            var index = container.data('form-collection-index');
            // var productId = $this.val();

            if (!onlySetData) {
                container.hide();
                var loader = $('<span class="loader" style="display:block;margin: 0 auto;"></span>');
                container.before(loader);
            }

            $.ajax({
                // url: $this.parents('form').data('ajax-url'),
                url: container.parents('form').data('ajax-url'),
                data: {
                    index: index,
                    productId: productId,
                    origin: 'front'
                }
            }).done(function(data) {
                var dataHtml = data.html;
                var steps = data.steps ? data.steps : null;
                var collectionItem = $(dataHtml).find('[data-form-collection="item"]').first();


                if (!onlySetData) {
                    loader.remove();
                    container.replaceWith(collectionItem);
                } else {
                    container.show();
                    collectionItem = container;
                }


                collectionItem
                    .data('tree', data.tree)
                    .data('steps', steps);

                if (!onlySetData) {
                    collectionItem.find('.sylius-autocomplete')
                        .shopAutoComplete();
                }

                collectionItem
                    .find('select[data-quotation-item-option-value-select="1"]')
                    .first()
                    .trigger('change')
                ;
            });
        }
    };
    $(document).on('change', '[name*="app_quotation_form"][name*="quotationItems"][name*="product"]', function(e, data) {

        var $this = $(this);

        var onlySetData = false;
        if (typeof data !== 'undefined' && data.onlySetData) {
            onlySetData = true;
        }

        NsQuotation.loadForm($this.parents('[data-form-collection="item"]'), $this.val(), onlySetData);
    });

    $(document).on('click', 'a[data-product-other-link]', function() {
        var $this = $(this);

        NsQuotation.loadForm($this.parents('[data-form-collection="item"]'), null);
    });

    $(document).on('change', '.is_imperative_delivery_date input', function() {
        var $this = $(this);
        var dataForm = $this.closest('div[data-form-collection="list"]');

        if ($this.val() == 0) {
            dataForm.find('.imperative_delivery_date').hide();
            dataForm.find('.delivery-date').val('');
        } else {
            dataForm.find('.imperative_delivery_date').show();
            var deliveryInput = dataForm.find('.delivery-date');
            deliveryInput.val(deliveryInput.attr('default-date'));
        }
    });

    $(document).on('change', '.radio-inline input[type="radio"]', function() {
        var $this = $(this);

        $this.closest('.radio').siblings('.radio').find('input[type="radio"]').removeAttr('checked');
    });

});
$(document).on('ready', function() {
    $(document).pocLoadDeferred();

    $(document).on('click', '.ytb-img[data-youtube-id]', function() {
        var $this = $(this);
        $this.replaceWith('<iframe src="https://www.youtube.com/embed/'+$this.attr('data-youtube-id')+'?autoplay=1" frameborder="0" width="'+$this.attr('data-width')+'" height="'+$this.attr('data-height')+'"></iframe>');
    });
});

$.fn.extend({
    pocLoadDeferred: function() {
        this.find('picture source').each(function() {
            var $this = $(this);
            if ($this.attr('data-srcset')) {
                $this.attr('srcset', $this.attr('data-srcset'))
                $this.addClass('deferred');
            }
        });

        this.find('img,iframe').each(function() {
            var $this = $(this);
            if ($this.attr('data-src')) {
                $this.on('load', function() {
                    $this.addClass('deferred');
                    $this.removeClass('defer');
                });
                $this.attr('src', $this.attr('data-src'))

                // if ($this.is('img')) {
                //     $this.css('width', '')
                //         .css('height', '');
                // }
            }
        });

        this.find('.defer-img-bgd').each(function() {
            var $this = $(this);
            if ($this.attr('data-img-src')) {
                $this.css('background-image', "url('"+$this.attr('data-img-src')+"')");
                $this.addClass('deferred');
            }
        });
    }
});
(function () {
    if (window.POC === undefined) window.POC = {};

    var DocumentSourceThumbnailsHandler = function (params) {
        params = params || {
            extraMaxFileNumber: null,
            printSide: null,
            delay: function () { return Promise.resolve(); }
        };

        function fetchSourceDocumentData(itemId, file, onPreData, count) {
            return new Promise(function (resolve, reject) {
                count = count === undefined ? 10 : count;
                if (count <= 0) {
                    reject(new Error("exceeded attempts to fetch preview data"));
                    return;
                }

                $.ajax(Routing.generate((itemId != undefined && itemId !== null) ? 'app_shop_order_item_document_show' : 'app_shop_order_item_show_document', { orderItemId: itemId, documentId: file.id }), {
                    method: 'GET',
                    cache: false
                }).done(function(data) {
                    if (data.thumbnailDocumentUploadState === 'uploaded') {
                        var pageCount = (data.extra != undefined && data.extra !== null && data.extra.hasOwnProperty('pageCount')) ? parseInt(data.extra.pageCount) : 0;
                        if (pageCount > 0 && onPreData !== undefined && onPreData !== null) {
                            onPreData(itemId, file, data);
                        }
                        resolve(data);
                    } else if (data.thumbnailDocumentUploadState === 'new' || data.thumbnailDocumentUploadState === 'partially_uploaded') {
                        return delayPromise(2000).then(function () {
                            if (onPreData !== null) {
                                var pageCount = (data.extra != undefined && data.extra !== null && data.extra.hasOwnProperty('pageCount')) ? parseInt(data.extra.pageCount) : 0;
                                if (pageCount > 0) {
                                    onPreData(itemId, file, data);
                                    return fetchSourceDocumentData(itemId, file, null, count - 1).then(resolve).catch(reject);
                                } else {
                                    return fetchSourceDocumentData(itemId, file, onPreData, count - 1).then(resolve).catch(reject);
                                }
                            } else {
                                return fetchSourceDocumentData(itemId, file, null, count - 1).then(resolve).catch(reject);
                            }
                        });
                    } else if (data.thumbnailDocumentUploadState === "partially_failed" || data.thumbnailDocumentUploadState === "failed") {
                        reject(new Error("thumbnail generation failed"));
                    } else if (data.thumbnailDocumentUploadState) {
                        reject(new Error("unhandled thumbnail state"));
                    }
                }).error(reject);
            });
        }

        function triggerThumbnailGeneration(file) {
            return Promise.resolve(
                $.ajax(Routing.generate('app_shop_document_source_generate_thumbnails', { id: file.id }), {
                    method: 'POST',
                    cache: false
                })
            );
        }

        function isThumbnailSupported(file) {
            var supportedPreviewImageType = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "application/pdf"
            ];

            return (supportedPreviewImageType.indexOf(file.type) >= 0);
        }

        function loadSourceThumbnails(itemId, file, opts) {
            return new Promise(function (resolve, reject) {
                if (!isThumbnailSupported(file)) {
                    reject(Translator.trans('app.shop.cart.upload.thumbnails.preview.unavailable'));
                    return;
                }

                // if (file.thumbnailState === "uploaded") {
                //     resolve(file);
                // } else if (file.thumbnailState === "new") {
                //     return triggerThumbnailGeneration(file).then(function (data) {
                //         if (data.thumbnailState !== undefined) {
                //             file.thumbnailState = data.thumbnailState;
                //             opts.vue.$forceUpdate();
                //         }
                //         return params.delay().then(function () {
                //             return loadSourceThumbnailsFromServer(file, opts);
                //         });
                //     }).then(resolve).catch(reject);
                // } else
                if (file.thumbnailState === "new" || file.thumbnailState === 'partially_uploaded' || file.thumbnailState === "uploaded") {
                    return loadSourceThumbnailsFromServer(itemId, file, opts).then(resolve).catch(reject);
                } else {
                    reject(Translator.trans('app.shop.cart.upload.thumbnails.preview.fail'));
                }
            }).catch(function (err) {
                file.thumbnailErrorReason = err;
                file.documentPageCount = file.documentPageCount ? file.documentPageCount : 0;
            });
        }

        function loadSourceThumbnailsFromServer(itemId, file, opts) {
            return fetchSourceDocumentData(itemId, file, opts.onDocumentPageCount)
                .then(function (data) {
                    file.thumbnailState = data.thumbnailDocumentUploadState;
                    file.documentPageCount = (data.extra != undefined && data.extra !== null && data.extra.hasOwnProperty('pageCount')) ? parseInt(data.extra.pageCount) : 0;
                    file.thumbnails = data.thumbnailDocuments;

                    // file.documentPageCount = data.documentPageCount;
                    // file.thumbnailState = data.thumbnailState;
                    // file.thumbnails = data.thumbnails;

                    opts.vue.$forceUpdate();
                })
                .catch(function (err) {
                    return Translator.trans('app.shop.cart.upload.thumbnails.preview.fail');
                });
        }

        // Define public interface
        this.loadSourceThumbnails = loadSourceThumbnails;
    };

    $.fn.extend({
        zoomThumbnail: function () {
            this.each(function (idx, el) {
                $(el).on('click', function () {
                    // var $modal = $('#preview-thumbnail-modal');
                    var $container = $(el).closest('.cart__attach_file_thumb-image');
                    var $picture = $container.find('.cart__attach_file_thumb-image_picture');
                    if ($picture.length === 0) {
                        $picture = $container.find('.product-preview__image__img');
                    }
                    if ($picture.length && $picture.attr('src')) {
                        var pictureSrc = $picture.attr('src'); // Open the picture in a new browser tab
                        window.open(pictureSrc, '_blank');
                    }
                });
            });
        }
    });

    POC.DocumentSourceThumbnailsHandler = DocumentSourceThumbnailsHandler;
}());

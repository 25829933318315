$(function(){
    var containers = $('.select-multiple-container');
    containers.find('select[data-select!="hidden"]').removeAttr('required').removeAttr('name').removeAttr('id').end().each(function() {
        var container = $(this);

        container.find('a[data-action="add"]').on('click', function() {
            container.find('select[data-select="left"] option:selected').appendTo(container.find('select[data-select="right"]'))
                .each(function() {
                    var $this = $(this);

                    $this.prop('selected', false);

                    $('<input>')
                        .attr('type', 'hidden')
                        .attr('name', container.data('fullname'))
                        .val($this.val())
                        .prependTo(container)
                    ;
                    container.trigger('sm-add', [$this.val()]);
                });

        });
        container.find('a[data-action="remove"]').on('click', function() {
            container.find('select[data-select="right"] option:selected').appendTo(container.find('select[data-select="left"]'))
                .each(function() {
                    var $this = $(this);

                    $this.prop('selected', false);
                    container.find('input:hidden[value="'+$this.val()+'"]').remove();
                    container.trigger('sm-remove', [$this.val()]);
                });
        });

    });
});

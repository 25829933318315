(function ($) {
    'use strict';

    var canvaApi = null;
    var canvaTokenSub = null;
    var canvaFormRequest = null;

    $('.canva-grid-banner__button, .canva-grid-element a').on('click', function(event) {
        event.preventDefault();

        var mappingItem = $(this).data('canva-mapping-item');
        var options = $(this).data('canva-options');

        showCanvaModal(null, {
            height: $(this).data('canva-height'),
            mappingItem: (valueIsEmpty(mappingItem) || $.isPlainObject(mappingItem)) ? mappingItem : JSON.parse(mappingItem),
            maxPages: $(this).data('canva-max-pages'),
            minPages: $(this).data('canva-min-pages'),
            options: (valueIsEmpty(options) || $.isPlainObject(options)) ? options : JSON.parse(options),
            orientation: $(this).data('canva-orientation'),
            redirectUrl: $(this).data('canva-redirect-url'),
            width: $(this).data('canva-width'),
        });
    });

    $('.canva-modal-error-close').on('click', function(event) {
        event.preventDefault();

        $(this).closest('.modal').modal('hide');
    });

    $(document).on('submit', 'form[name="app_canva"]', function(event) {
        event.preventDefault();

        var form = $(this);
        var modal = form.closest('.modal');
        var formContainer = $(modal.find('.modal-form')[0]);
        var formLoader = $(modal.find('.modal-loader')[0]);

        formContainer.hide();
        formLoader.show();

        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize()
        }).done(function (result) {
            modal.modal('hide');

            showCanvaModal(null, result);
        }).fail(function (result) {
            formLoader.hide();
            formContainer.show();
        });
    });

    window.showCanvaModal = function(document, params) {
        document = document || null;
        params = params || {};

        var mappingItem = (!valueIsEmpty(params.mappingItem)) ? params.mappingItem : {};
        var mapping = (!valueIsEmpty(mappingItem.mapping)) ? mappingItem.mapping : {};

        var modal = $('#canva-modal');
        var modalErrorMessage = modal.find('.canva-modal-error-message');

        if (valueIsEmpty(document)) {
            if (valueIsEmpty(mapping) || !valuesAreNotEmpty(params, ['height', 'maxPages', 'minPages', 'orientation', 'width'])) {
                return showCanvaFormModal();
            } else if (valueIsEmpty(mapping.canvaType) || valueIsEmpty(mapping.canvaProductId)) {
                showCanvaModalError(modal);

                modal.modal('show');

                return;
            }
        }

        var designId = null;
        var ownerId = (!valueIsEmpty(document)) ? document.ownerId : params.ownerId;

        getCanvaApi(modal, (!valueIsEmpty(document)) ? document.extra.tokenSub : null).then(function (api) {
            var apiParams = {
                maxPages: (!valueIsEmpty(params.maxPages)) ? params.maxPages : document.extra.pageCount,
                minPages: (!valueIsEmpty(params.minPages)) ? params.minPages : document.extra.pageCount,
                onArtworkCreate: function (data) {
                    showCanvaModalLoader(modal);

                    modal.modal('show');

                    var formData = new FormData();

                    formData.append('app_document_type[builtWithCanva]', '1');
                    formData.append('app_document_type[code]', 'document' + ((data.pageCount == 2) ? '_recto' : ''));
                    formData.append('app_document_type[extra]', JSON.stringify({
                        'artworkId': data.artworkId,
                        'designId': designId,
                        'isPreview': true,
                        'mappingItem': (!valueIsEmpty(mappingItem)) ? mappingItem : ((!valueIsEmpty(document) && !valueIsEmpty(document.extra.mappingItem)) ? document.extra.mappingItem : null),
                        'options': (!valueIsEmpty(params.options)) ? params.options : ((!valueIsEmpty(document) && !valueIsEmpty(document.extra.options)) ? document.extra.options : null),
                        'orientation': (!valueIsEmpty(params.orientation)) ? params.orientation : ((!valueIsEmpty(document) && !valueIsEmpty(document.extra.orientation)) ? document.extra.orientation : null),
                        'pageCount': data.pageCount,
                        'tokenSub': canvaTokenSub,
                        'url': data.previewImageSrc
                    }));
                    formData.append('app_document_type[mimeType]', 'application/octet-stream');
                    formData.append('app_document_type[name]', data.artworkTitle);
                    formData.append('app_document_type[path]', '/');
                    formData.append('app_document_type[type]', 'source');
                    formData.append('app_document_type[uniqueId]', designId + '-' + data.artworkId);

                    var route;

                    if (!valueIsEmpty(document)) {
                        route = (!valueIsEmpty(ownerId)) ? 'app_shop_order_item_document_update' : 'app_shop_order_item_update_document';
                    } else {
                        route = (!valueIsEmpty(ownerId)) ? 'app_shop_order_item_document_create' : 'app_shop_order_item_create_document';
                    }

                    var newEntry = valueIsEmpty(document) || valueIsEmpty(document.extra.designId);

                    $.ajax(Routing.generate(route, {
                        documentId: (!valueIsEmpty(document)) ? document.id : null,
                        orderItemId: (!valueIsEmpty(ownerId)) ? ownerId : null
                    }), {
                        method: 'POST',
                        cache: false,
                        async: true,
                        contentType: false,
                        processData: false,
                        data: formData
                    }).done(function (document) {
                        modal.modal('hide');

                        if (newEntry) {
                            dataLayer.push({
                                event: 'virtualPageView',
                                virtualPagePath: '/virtual-page-canva-complete-new',
                                virtualPageTitle: 'Virtual Canva Complete New'
                            });
                        } else {
                            dataLayer.push({
                                event: 'virtualPageView',
                                virtualPagePath: '/virtual-page-canva-complete-edit',
                                virtualPageTitle: 'Virtual Canva Complete Edit'
                            });
                        }

                        if (!valueIsEmpty(params.onComplete)) {
                            if (typeof window[params.onComplete] === 'function') {
                                window[params.onComplete](document);
                            } else {
                                params.onComplete(document);
                            }
                        }

                        if (!valueIsEmpty(params.redirectUrl)) {
                            window.location.href = params.redirectUrl.replace('__documentId__', document.id);
                        }
                    }).fail(function(result) {
                        modalErrorMessage.html(Translator.trans((designId.length > 0) ? 'app.ui.canva.error.update_document' : 'app.ui.canva.error.create_document'));

                        showCanvaModalError(modal);
                    });
                },
                onBackClick: function () {

                },
                onDesignOpen: function (data) {
                    designId = data.designId;

                    if (valueIsEmpty(document) || valueIsEmpty(document.extra.designId)) {
                        dataLayer.push({
                            event: 'virtualPageView',
                            virtualPagePath: '/virtual-page-canva-open-new',
                            virtualPageTitle: 'Virtual Canva Open New'
                        });
                    } else {
                        dataLayer.push({
                            event: 'virtualPageView',
                            virtualPagePath: '/virtual-page-canva-open-edit',
                            virtualPageTitle: 'Virtual Canva Open Edit'
                        });
                    }
                }
            };

            if (!valueIsEmpty(document) && !valueIsEmpty(document.extra.designId)) {
                apiParams.designId = document.extra.designId;

                api.editDesign(apiParams);
            } else {
                apiParams.partnerProductId = mapping.canvaProductId;
                apiParams.type = mapping.canvaType;
                apiParams.height = params.height;
                apiParams.units = 'mm';
                apiParams.width = params.width;

                api.createDesign(apiParams);
            }
        }, function (error) {
            modalErrorMessage.html(Translator.trans('app.ui.canva.error.initialize'));

            showCanvaModalError(modal);
        });
    };

    function showCanvaModalError(modal) {
        var content = modal.find('.canva-modal-content');

        content.css('background-color', '#fff');
        content.css('border', '1px solid rgba(0,0,0,.2)');
        content.css('box-shadow', '0 2px 6px rgb(115 115 115 / 33%)');

        modal.find('.canva-modal-loader').css('visibility', 'hidden');
        modal.find('.canva-modal-error').css('visibility', 'visible');
    }

    function showCanvaModalLoader(modal) {
        var content = modal.find('.canva-modal-content');

        content.css('background-color', 'transparent');
        content.css('border', 'none');
        content.css('box-shadow', 'none');

        modal.find('.canva-modal-error').css('visibility', 'hidden');
        modal.find('.canva-modal-loader').css('visibility', 'visible');
    }

    function showCanvaFormModal() {
        var modal = $('#canva-form-modal');

        $(modal.find('.modal-loader')[0]).hide();
        $(modal.find('.modal-form')[0]).show();

        modal.modal('show');
    }

    $(document).on('change', '#app_canva_format', function(event) {
        event.preventDefault();

        if (canvaFormRequest != null) {
            canvaFormRequest.abort();
            canvaFormRequest = null;
        }

        var format = $(this);
        var orientation = $('#app_canva_orientation');

        var form = format.closest('form');
        var orientationContainer = form.find('[data-name="orientation"]');
        var orientationLoader = form.find('[data-name="orientation-loader"]');
        var buttons = form.find('.btn');

        orientationContainer.hide();
        orientationLoader.show();
        buttons.each(function() {
            $(this).attr('disabled', 'disabled');
        });

        var data = {};
        data[format.attr('name')] = format.val();
        data[orientation.attr('name')] = orientation.val();
        data[$('#app_canva_invalidate').attr('name')] = true;

        canvaFormRequest = $.ajax({
            url : form.attr('action'),
            type: form.attr('method'),
            data : data,
            success: function(html) {
                if (canvaFormRequest == null) {
                    return;
                }

                orientation.html($(html).find('#app_canva_orientation').html());
                orientation.trigger('change');

                orientationLoader.hide();
                orientationContainer.show();
                buttons.each(function() {
                    $(this).removeAttr('disabled');
                });

                canvaFormRequest = null;
            }
        });
    });

    function getCanvaApi(modal, canvaTokenSub) {
        return new Promise(function (resolve, reject) {
            if (canvaApi === null) {
                if (modal !== null && modal.length > 0) {
                    showCanvaModalLoader(modal);

                    modal.modal('show');
                }

                getCanvaInitParams(canvaTokenSub)
                    .then(function (params) {
                        canvaTokenSub = params.canvaTokenSub;

                        return initCanva(params)
                    }, reject)
                    .then(function (api) {
                        if (modal !== null && modal.length > 0) {
                            modal.modal('hide');
                        }

                        resolve(canvaApi = api);
                    }, reject);
            } else {
                resolve(canvaApi);
            }
        });
    }

    function getCanvaInitParams(canvaTokenSub) {
        var ret = $.get(Routing.generate('app_shop_ajax_canva_init_params', { 'canvaTokenSub': (!valueIsEmpty(canvaTokenSub)) ? canvaTokenSub : null }));

        return ret
    }

    function initCanva(params) {
        return Canva.Partnership.initialize(params.init);
    }

    function valuesAreNotEmpty(object, keys) {
        for (var i = 0; i < keys.length; ++i) {
            if (valueIsEmpty(object[keys[i]])) {
                return false;
            }
        }

        return true;
    }

    function valueIsEmpty(value) {
        return (value == undefined || value == null || ($.isPlainObject(value) && $.isEmptyObject(value)) || value.length == 0);
    }
})(jQuery);

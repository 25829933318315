(function($) {
    $(document).ready(function() {
        $('#sylius-shipper-address').addressBook();

        $('.sylius-autocomplete').shopAutoComplete();

        if (!$('#vue-container-product').length) {
            $('.modal.uploadable').uploadVue();
            $('main').uploadVue();
        }

        $('input[name*="customerReference"]').on('focus', function() {
            $('.order-panel.confirm-order').addClass('hidden-mobile');
        }).on('blur', function() {
            $('.order-panel.confirm-order').removeClass('hidden-mobile');
        });

        $('form.register-step1').on('submit', function(e) {
            var $this = $(this);
            var terms = $this.find('input:checkbox[name*="termsOfProtectionPrivateInformation"]');
            if (terms.length > 0 && terms.first().not(':checked').length > 0) {
                e.preventDefault();
                $this.find('#dpd.has-error').show();
            }
        });

        // https://stackoverflow.com/questions/7358781/tapping-on-label-in-mobile-safari
        $('label').click(function() {});

        // Called when the user click on the send button in the modal ticket show
        // Submit a ticket item form
        $(document).on('click', '.modal .modal-submit-button:not([no-loader])', function () {
            var $relatedTarget = $(this);
            $relatedTarget.hide();
            if ($relatedTarget.next().hasClass('loader')) {
                $relatedTarget.next().show();
            } else {
                $relatedTarget.after('<div class="loader cart-loader"></div>');
            }

            $(this).closest('.modal-dialog').find('form').first().trigger('submit', [{
                relatedTarget: $(this)
            }]);
        });

        var form = $('#sylius-product-adding-to-cart');

        form.on('submit', function() {
            // GTM / Enhanced ecommerce addToCart
            gtmAddToCartProductInfo.variant = $(this).data('variant');
            enhancedEcommerceAddToCart(gtmAddToCartProductInfo);
        });


        // Cloaked menu
        $('.ajax-load-menu').on('mouseenter', function() {
            var $this = $(this);
            if ($this.data('loaded') || $this.data('loading')) {
                return;
            }

            $this.data('loading', true);

            $.ajax($this.data('ajaxUrl'), {
                method: "GET",
                data: {
                    id: $this.data('taxonId')
                }
            }).done(function(data) {
                $this.append(data);
                $this.data('loaded', true);
            }).always(function() {
                $this.data('loading', false);
            });
        });

        // Part of cloaked link
        $(document).on('click', 'span.link,li.link,div.link', function() {
            if (!$(this).hasAttr('data-link')) {
                return;
            }

            if ($(this).attr('data-target') == "_blank"){
                $('<a href="'+ $(this).attr('data-link') +'" target="_blank">Link</a>')[0].click();
            } else {
                window.location.href = $(this).attr('data-link');
            }
        });

        // Ask Payment Facilities
        $(document).on('hidden.bs.modal', '#direct-debit-step1', function(){
            var modalStep1 = $('#direct-debit-step1');
            if ($(this).data('showModalStep2')) {
                $('#direct-debit-step2').modal('show');
                modalStep1.data('showModalStep2', false);
            }
        });

        $(document).on('submit', '#direct-debit-step2 form', function(e) {
            e.preventDefault();
            var $this = $(this);
            var formData = new FormData($this[0]);
            $.ajax($this.attr('action'), {
                method: $this.attr('method'),
                data: formData,
                enctype: 'multipart/form-data',
                processData: false,
                contentType: false
            }).done(function(data, textStatus, jqXhr) {
                window.location.href = data.redirect;
            }).fail(function(data, textStatus, jqXhr) {
                $this.parents('.modal-content').html(data.responseText);
                $('#direct-debit-step2').uploadVue();
            });


            return false;
        });

        // Hack with css checkboxes
        $('input:checkbox[checked="checked"]').removeAttr('checked').prop('checked', true);

        // Checkout fix bottom bar
        var barConfirmOrder = $('.confirm-order');
        if (barConfirmOrder.length > 0) {
            $(window).on('scroll', function(){
                var sticky = barConfirmOrder.outerHeight() + parseInt(barConfirmOrder.offset().top) + 1 - $(window).outerHeight();

                if (window.pageYOffset <= sticky) {
                    var footerTop = parseInt($('.footer').offset().top);
                    var confirmOrderHeight = barConfirmOrder.outerHeight();
                    if (window.pageYOffset + $(window).outerHeight() - confirmOrderHeight >= footerTop ) {
                        barConfirmOrder.removeClass('confirm-order__bottom-fixed');
                    } else {
                        barConfirmOrder.addClass('confirm-order__bottom-fixed');
                    }
                } else {
                    barConfirmOrder.removeClass('confirm-order__bottom-fixed');
                }
            }).trigger('scroll');
        }

        $(document).on('click', '#btn-save-quotation', function(e) {
            e.preventDefault();
            var $this = $(this);

            $.ajax($this.data('url'), {
                method: 'POST',
            }).done(function(data, textStatus, jqXhr) {
                window.location.reload();
            });

            return false;
        });

        var $flashNotif = $('#flashNotification');
        // show notif everytime for COVID19 crisis.
        if ($flashNotif.length && false) {
            var hashNotif = 'flashNotification'+btoa($flashNotif.html()).substring(0, 4);
            $(document).on('click', '.flash-notification .flash-message-cancel', function() {
                var d = new Date();
                d.setTime(d.getTime() + (24*60*60*1000*365));
                document.cookie = hashNotif+"=1; expires="+d.toUTCString()+"; path=/";
            });
            if (!document.cookie.match('(^|[^;]+)\\s*' + hashNotif + '\\s*=\\s*([^;]+)')) {
                $flashNotif.show();
            }
        }

        $(document).on('click', '.flash-rgpd .btn-ok', function() {
            var d = new Date();
            d.setTime(d.getTime() + (24*60*60*1000*365));
            document.cookie = "flashRgpd=1; expires="+d.toUTCString()+"; path=/";

            $(this).closest(".flash-message").slideUp();
        });

        $(document).on('click', '.main-menu__wrap .close', function() {
            $('.menu-drop-show, .menu-drop-active').removeClass('menu-drop-show').removeClass('menu-drop-active');
        });

        $(document).on('click', '.modal-technical-specs', function(e) {
            e.preventDefault();
            var $this = $(this);
            var url = atob($this.attr('poc-clkk').split("").reverse().join(""));
            var val = $this.attr('data-selected-option-values');
            $('#technical-specs').modal('show');
            $.ajax(url, {
                method: 'GET',
                data: {
                    selectedOptionValues: val
                }
            }).done(function(data, textStatus, jqXhr) {
                $('#technical-specs .modal-content').html(data);
            });

            return false;
        });


        var ekomiIntervalIt = 0;
        // function str_rot13(str) {
        //     var tarluDecoded = atob(str.split("").reverse().join(""));
        //     return atob(tarluDecoded);
        // }

        var ekomiInterval = setInterval(function() {
            ekomiIntervalIt++;
            var a = $('#eKomiWidget_default a');
            if (a.length) {
                var span = $('<span></span>');
                span.attr('id', a.attr('id'));
                span.attr('title', a.attr('title'));
                span.attr('poc-clkk', btoa('/rs-ekomi').split("").reverse().join(""));
                span.addClass('link');
                span.html(a.html());
                // a.attr('href', '/rs-ekomi');
                a.replaceWith(span);
                clearInterval(ekomiInterval);
            }
            if (ekomiIntervalIt >= 5) {
                clearInterval(ekomiInterval);
            }
        }, 5000, ekomiIntervalIt);

        $('form[name*="address"]').find('[type="submit"]').on('click', function(e) {
            var $this = $(this);
            var form = $this.closest('form');

            var applyToAll = form.find('[name*="applyToAll"]');
            if (applyToAll.length > 0 && !applyToAll.is(':checked')) {
                e.preventDefault();
                $('#address-confirm-apply-to-all').modal('show');
            }
        });

        $('#address-confirm-apply-to-all button[data-answer]').on('click', function() {
            var form = $('form[name*="address"]');
            if ($(this).data('answer') == 'yes') {
                form.find('[name*="applyToAll"]').prop('checked', true);
            } else {
                form.find('[name*="applyToAll"]').prop('checked', false);
            }

            form.submit();
        });

        $('.product-preview__image__edit').on('click', function(event) {
            event.preventDefault();

            $('.content-nav__list-item[data-step-option="1"]')[0].__vue__.onStepClicked(1)
        });
    });

    $.fn.extend({
        hasAttr: function(name) {
            return this.attr(name) !== undefined;
        },

        attrOrDefault: function(name, defaultValue) {
            return (this.hasAttr(name)) ? this.attr(name) : defaultValue;
        },

        uploadVue: function () {
            this.each(function (idx, el) {
                if (typeof Vue === "undefined" || jQuery(el)[0].__vue__) {
                    return;
                }

                new Vue({
                    el: el,
                    data: {
                        draggingState: 'idle',
                        componentKey: 0,
                        elementId: $(this).attrOrDefault('id', null)
                    },
                    methods: {
                        onDragEnter: function (e) {
                            if (e.relatedTarget === null) {
                                this.draggingState = 'dragging';
                            }
                            event.preventDefault();
                        },
                        onDragLeave: function (e) {
                            if (e.relatedTarget === null) {
                                this.draggingState = 'idle';
                            }
                        }
                    },
                    beforeMount: function () {
                        document.addEventListener('dragenter', this.onDragEnter, false);
                        document.addEventListener('dragleave', this.onDragLeave, false);
                    }
                });
            });
        },
        hasAttr: function(name) {
            return this.attr(name) !== undefined;
        },

        attrOrDefault: function(name, defaultValue) {
            return (this.hasAttr(name)) ? this.attr(name) : defaultValue;
        }
    });
})(jQuery);

function onAjaxDirectDebitStep1Success(form, result) {
    var modalStep1 = $('#direct-debit-step1');
    modalStep1.data('showModalStep2', true);
    modalStep1.modal('hide');

}

function delayPromise(duration) {
    return new Promise(function(resolve, reject) {
        setTimeout(function() {
            resolve();
        }, duration)
    });
}

(function($) {
    $(document).ready(function() {
        $('.grid_filter').find('.content-nav__menu-item').on('click', function(event) {
            event.preventDefault();

            var filterId = $(this).data("filterId");
            var filterValue = $(this).data("filterValue");
            var $filterField = $('#' + filterId);
            var $filterForm = $filterField.parents('.grid_filter');

            if (filterValue === 'all') {
                $filterField.find('option:selected').removeAttr('selected');
            } else {
                $filterField.val(filterValue);
            }

            $filterForm.submit();
        });
    });
})(jQuery);

(function ($) {
    'use strict';

    $(document).ready(function() {
        $(window).on('scroll', function () {
            var containersInfiniteScroll = $(window).data('containers-infinite-scroll') || [];
            for (var iContainerScroll in containersInfiniteScroll) {
                var scrollContainer = $(containersInfiniteScroll[iContainerScroll]);
                var clone = scrollContainer.find('.clone');
                var lastChild = scrollContainer.find('.infinite-scroll-last-element');
                var loader = scrollContainer.find('.infinite-scroll-loader');
                var onAllLoadedCallback = scrollContainer.attrOrDefault('data-on-all-loaded', '');

                if (!scrollContainer.data('ajax-processing') && scrollContainer.data('page') < scrollContainer.data('nbpages') && MyUtils.isInView(loader.get(0))) {
                    scrollContainer.data('page', scrollContainer.data('page') + 1);
                    scrollContainer.data('ajax-processing', true);
                    $.ajax({
                        url: scrollContainer.data('url'),
                        data: {
                            page: scrollContainer.data('page')
                        },
                        dataType: 'json'
                    }).done(function(response) {
                        var json = response;

                        for (var index in json) {
                            var html = clone.html();
                            html = html.replace('__SLUG__', json[index].slug);
                            html = html.replace('__NAME__', json[index].name);
                            html = html.replace('__TEMPLATE_IMG_SRC__', json[index].imageSrc);

                            if (lastChild != undefined && lastChild.length > 0) {
                                lastChild.before(html);
                            } else {
                                loader.before(html);
                            }
                        }
                    }).always(function() {
                        scrollContainer.data('ajax-processing', false);

                        if (scrollContainer.data('page') >= scrollContainer.data('nbpages')) {
                            loader.hide();

                            if (onAllLoadedCallback.length > 0 && typeof window[onAllLoadedCallback] === "function") {
                                window[onAllLoadedCallback](scrollContainer);
                            }
                        }
                    });
                }
            }
        });

    });

})(jQuery);

$.fn.extend({
    pocInfiniteScroll: function(options) {
        var $this = $(this);
        var containers = $(window).data('containers-infinite-scroll') || [];

        containers.push($this);

        $(window).data('containers-infinite-scroll', containers);

        if ($this.data('page') < $this.data('nbpages')) {
            $this.append($('<div class="infinite-scroll-loader" style="width: 100%;"><div class="loader" style="margin: 0 auto;"></div></div>'));
        }
    }
});

(function ($) {
    'use strict';

    $(document).ready(function() {
        $('body').on('submit', '.ajax.form', function (event, params) {
            var relatedTarget = event.relatedTarget || null;
            if (!relatedTarget && typeof params !== 'undefined') {
                relatedTarget = params.relatedTarget;
            }

            var form = $(this);

            if (!relatedTarget) {
                relatedTarget = form.find('.related-target').first();
            }

            var onSuccess = form.attrOrDefault('data-on-success', 'onAjaxFormSuccess');
            var onError = form.attrOrDefault('data-on-error', 'onAjaxFormError');

            if (form.hasClass('loadable')) {
                form.removeClass('loadable');
                form.addClass('loading');
            }

            event.preventDefault();

            if (relatedTarget) {
                var $relatedTarget = $(relatedTarget);
                $relatedTarget.hide();
                if ($relatedTarget.next().hasClass('loader')) {
                    $relatedTarget.next().show();
                } else {
                    $relatedTarget.after('<div class="loader cart-loader"></div>');
                }
            }

            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: form.serialize()
            }).done(function (result) {
                if (onSuccess !== 'null' && typeof window[onSuccess] === "function") {
                    window[onSuccess](form, result);
                }
            }).fail(function (result) {
                if (onError !== 'null' && typeof window[onError] === "function") {
                    window[onError](form, result);
                }
            }).always(function() {
                if (typeof $relatedTarget !== 'undefined' && $relatedTarget) {
                    $relatedTarget.show().next('.loader').hide();
                }
            });

            return false;
        });
    });

    $(document).on('keyup', 'input[floating-label="true"], textarea[floating-label="true"]', function() {
        $(this).attr('value', $(this).val())
    });

    $(document).on('change', 'input[floating-label="true"], textarea[floating-label="true"]', function() {
        $(this).attr('value', $(this).val())
    });

    $('input[floating-label="true"], textarea[floating-label="true"]').change();

    $.fn.extend({
        setErrors: function (errors, form, recursiveCall) {
            if (!recursiveCall) {
                $(form).find('.error-message').remove();
                $(form).find('.form-group').removeClass('has-error');
                $(form).find('.global-errors').remove();
            }

            var globalFormErrors = [];

            for (var key in errors) {
                if (errors.hasOwnProperty(key)) {
                    // Global form error
                    if (key == parseInt(key, 10)) {
                        globalFormErrors.push(errors[key]);
                    } else {



                        var errorMessages = errors[key];
                        if (!Array.isArray(errorMessages)) {
                            form.setErrors(errorMessages, form, true);
                        } else {
                            var input = $(this.find('[name*="' + key + '"]')[0]);
                            var group = input.closest('.form-group');

                            group.addClass('has-error');

                            // Add new errors
                            for (var i in errorMessages) {
                                if (errorMessages.hasOwnProperty(i)) {
                                    if (input.is(':checkbox')) {
                                        input.next('span').after('<div class="has-error"><p class="error-message">' + errorMessages[i] + '</p></div>');
                                    } else {
                                        input.after('<div class="has-error"><p class="error-message">' + errorMessages[i] + '</p></div>');
                                    }

                                }
                            }
                        }
                    }
                }
            }

            if (globalFormErrors.length > 0) {
                if ($(form).find('.global-form-errors').length > 0) {
                    var globalErrors = $(form).find('.global-form-errors').addClass('has-error');
                } else {
                    var globalErrors = $('<div class="has-error"></div>').addClass('global-form-errors');
                    $(form).prepend(globalErrors);
                }

                for (var index in globalFormErrors) {
                    globalErrors.append('<p class="error-message">'+globalFormErrors[index]+'</p>')
                }

                globalErrors.show();
            }
        },
        setErrorsFos: function (errors, form) {
            $(form).find('.error-message').remove();
            $(form).find('.form-group').removeClass('has-error');

            if (errors.hasOwnProperty('children')) {
                for (var key in errors.children) {
                    if (errors.children[key].hasOwnProperty('errors')) {
                        var input = $(this.find('[name*="' + key + '"]')[0]);
                        var group = input.closest('.form-group');
                        group.addClass('has-error');
                        for (var i in errors.children[key].errors) {
                            input.after('<div class="has-error"><p class="error-message">' + errors.children[key].errors[i] + '</p></div>');
                        }
                    }
                }
            }
        }
    });

})(jQuery);

function onAjaxFormSuccess(form, result) {
    location.reload();
}

function onAjaxFormError(form, result) {
    if (result.hasOwnProperty('responseJSON')) {
        var json = result.responseJSON;
        if (json.hasOwnProperty('errors')) {
            form.setErrors(json.errors, form);
        }
    }

    if (form.hasClass('loading')) {
        form.removeClass('loading');
        form.addClass('loadable');
    }
}

function onAjaxFormErrorFos(form, result) {
    if (result.hasOwnProperty('responseJSON')) {
        var json = result.responseJSON;
        if (json.hasOwnProperty('errors')) {
            form.setErrorsFos(json.errors, form);
        }
    }

    if (form.hasClass('loading')) {
        form.removeClass('loading');
        form.addClass('loadable');
    }
}

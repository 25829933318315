(function($) {
    $(document).ready(function() {
        // Move all support modal to the end of the body
        // Allow to add support modal in partial without loose interaction
        $(document.body).append($('div[id^=support-modal-]').detach());

        $('#ajax-modal').modal({ show: false });

        $(document).on('click', '[data-toggle="ajax-modal"][data-target="#ajax-modal"][data-url]', function(e) {
            e.preventDefault();

            var $this = $(this);
            var onShowCallback = $this.attrOrDefault('data-on-show', '');
            var ajaxModal = $('#ajax-modal');

            if (ajaxModal.attrOrDefault('data-modal-ajax-loading', '0') == '1') {
                return;
            }

            if ($this.attr('data-wizard') == 'reseller-subscribe') {
                ajaxModal.removeClass('modal_lg');
            }

            ajaxModal.attr('data-modal-ajax-loading', '1');

            ajaxModal.find('.modal-content').html('<div class="loader"></div>');

            var currentModal = $this.closest('.modal');
            if (currentModal.length > 0) {
                currentModal.modal('hide');
            }

            ajaxModal.modal('show');

            $.ajax({
                url: $this.attr('data-url'),
                type: $this.attrOrDefault('data-method', 'GET'),
                cache: false
            }).done(function(data) {
                ajaxModal.find('.modal-content').html(data).uploadVue();
            }).fail(function(error) {

            }).always(function() {
                ajaxModal.attr('data-modal-ajax-loading', '0');

                if (onShowCallback.length > 0 && typeof window[onShowCallback] === "function") {
                    window[onShowCallback](ajaxModal);
                }
            });
        });

        $('.modal[data-init="1"]').modal();
    });
})(jQuery);

$(function() {
    MyUtils = {
        cumulativeOffset: function(element) {
            var top = 0, left = 0;
            do {
                top += element.offsetTop  || 0;
                left += element.offsetLeft || 0;
                element = element.offsetParent;
            } while(element);

            return {
                top: top,
                left: left
            };
        },
        offset: function(el) {
            var rect = el.getBoundingClientRect(),
                scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
        },
        // https://codepen.io/bfintal/pen/Ejvgrp
        isInView: function(el) {
            var scroll = window.scrollY || window.pageYOffset;
            var boundsTop = el.getBoundingClientRect().top + scroll

            var viewport = {
                top: scroll,
                bottom: scroll + window.innerHeight,
            };

            var bounds = {
                top: boundsTop,
                bottom: boundsTop + el.clientHeight,
            }

            return ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom )
                || ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );
        }

    };
});



$.fn.extend({
    shopAutoComplete: function() {
        this.each(function(idx, el) {
            var $el = $(el);

            var input = $el.find('.autocomplete');
            var data = {};

            var inputAutocomplete = input.autocomplete({
                source: function(request, response) {
                    data[$el.data('criteria-name')] = request.term;
                    $.ajax({
                        url: $el.data('url'),
                        dataType: 'json',
                        data: data,
                        success: function(data) {
                            response(data);
                        }
                    });

                },
                minLength: 1,
                select: function(event, ui) {
                    $('#'+input.data('autocomplete-id')).val(ui.item.extra.id).trigger('change');
                },
                search: function () {
                    $el.addClass('shop-autocomplete-loading')
                },
                response: function (event, ui) {
                    $el.removeClass('shop-autocomplete-loading')
                }
            });

            inputAutocomplete.autocomplete('instance')._renderItem = function(ul, item) {
                return $( "<li>" )
                    .attr( "data-value", item.extra.id )
                    // .attr( "data-value", item.id )
                    .append( item.idxTitle )
                    // .append( item.value )
                    .addClass('autocomplete-suggestions-item')
                    .appendTo( ul );
            };

            inputAutocomplete.autocomplete('instance')._renderMenu = function(ul, items) {
                var that = this;
                $.each( items, function( index, item ) {
                    that._renderItemData( ul, item );
                });
                ul.addClass('autocomplete-suggestions').addClass('v1').attr('data-autocomplete-id', inputAutocomplete.attr('data-autocomplete-id'));
            };

            inputAutocomplete.autocomplete('instance')._resizeMenu = function() {
                var menu = this.menu.element;
                menu.outerWidth($('[data-autocomplete-id="'+menu.attr('data-autocomplete-id')+'"]').outerWidth());
            };

            var inputHidden = $('#'+input.data('autocomplete-id'));
            data[$el.data('choice-value')] = inputHidden.val();
            $.ajax({
                url: $el.data('load-edit-url'),
                dataType: 'json',
                data: data,
                success: function(data) {
                    if (data) {
                        input.val(data.name);
                    }
                }
            });
        });

        return this;
    },
});

(function($) {
    $(function(){
        if ($('.landing-page').length > 0) {
            return;
        }

        // Get the navbar
        var navbar = $('.header__menu');

        // Get the header
        var pageHeader = $('.page__header');

        if (navbar.length) {
            // Get the element right after the navbar
            var element = $('#menu');

            // Get the offset position of the navbar
            var sticky = navbar.offset().top;

            $(window).on('scroll', function(){
                if (window.pageYOffset >= sticky) {
                    navbar.addClass("header__menu-sticky");
                    element.addClass("nav-sticky");
                    pageHeader.addClass('has-header-menu-stikcy');
                } else {
                    navbar.removeClass("header__menu-sticky");
                    element.removeClass("nav-sticky");
                    pageHeader.removeClass('has-header-menu-stikcy');
                }
            });
        }
    });
})(jQuery);

(function($) {
    $(function () {
        // Remove address
        $('.address-list__control-link.icon-basket, .address-list__actions a[data-action="delete"]').on('click', function(e) {
            e.preventDefault();
            $(this).parents('.address-list__item').find('.container-form-delete-address form').submit();
            return false;
        });

        // Set address as default
        $('.address-list__control-link.icon-lock, .address-list__actions a[data-action="set-default-billing"]').on('click', function(e) {
            e.preventDefault();
            $(this).parents('.address-list__item').find('.container-form-set-default-address-billing form').submit();
            return false;
        });

        // Set address as default shipping
        $('.address-list__actions a[data-action="set-default-shipping"]').on('click', function(e) {
            e.preventDefault();
            $(this).parents('.address-list__item').find('.container-form-set-default-address-shipping form').submit();
            return false;
        });
    });
})(jQuery);


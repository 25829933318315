(function($) {
    $(document).ready(function() {
        var wizardModal = $('#wizard-modal');

        if (wizardModal.length <= 0) {
            return;
        }

        wizardModal.modal({ show: false });

        $(document).on('click', '[data-toggle="wizard-modal"][data-target="#wizard-modal"][data-url]', function(e) {
            e.preventDefault();
            var $this = $(this);
            var onShowCallback = $this.attrOrDefault('data-on-show', '');

            var ajaxModal = $('#ajax-modal');
            if (ajaxModal.data('modal-ajax-loading')) return;

            ajaxModal.data('modal-ajax-loading', true);

            $.ajax({
                url: $this.data('url'),
                type: $this.data('method') || 'GET'
            }).done(function(data) {
                ajaxModal.find('.modal-content').html(data).uploadVue();
            }).fail(function(data) {

            }).always(function() {
                ajaxModal.data('modal-ajax-loading', false);
                // ajaxModal.modal();
                ajaxModal.find('.product-valider').validateBATForm(); // dirty
                ajaxModal.modal('show');

                if (onShowCallback.length > 0 && typeof window[onShowCallback] === "function") {
                    window[onShowCallback](ajaxModal);
                }
            });

            // ajaxModal.modal();

        });

        // ajaxModal.on('hidden.bs.modal', function() {
        //     $(this).modal('dispose');
        // });

        var urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('wizard')) {
            $('[data-wizard="'+urlParams.get('wizard')+'"]').trigger('click');
        }
    });

    var PocWizard = {

    };


})(jQuery);

(function ($) {
    'use strict';

    window.syncOrderItemDocumentThumbnails = function(id, ownerId, retryCount, retryDelay) {
        ownerId = ownerId || null;
        retryCount = (retryCount != undefined && retryCount != null) ? retryCount : 10;
        retryDelay = (retryDelay != undefined && retryDelay != null) ? retryDelay : 2000;

        return new Promise(function(resolve, reject) {
            if (retryCount < 0) {
                reject(new Error('The number of attempts to synchronize the document thumbnails has been reached'));
            } else {
                $.ajax(Routing.generate((ownerId != null) ? 'app_shop_order_item_document_show' : 'app_shop_order_item_show_document', {
                    orderItemId: ownerId,
                    documentId: id,
                    syncMetadata: 1
                }), {
                    method: 'GET',
                    cache: false
                }).done(function (data) {
                    data = data || null;

                    if (data == null) {
                        reject(new Error('Missing response data'));
                    } else if (data.thumbnailDocumentUploadState === 'uploaded') {
                        resolve(data);
                    } else if (data.thumbnailDocumentUploadState === 'partially_failed' || data.thumbnailDocumentUploadState === 'failed') {
                        reject(new Error('The thumbnails uploading has been failed'));
                    } else if (data.thumbnailDocumentUploadState === 'new' || data.thumbnailDocumentUploadState === 'partially_uploaded') {
                        return Promise
                            .delay(retryDelay)
                            .then(function () {
                                return syncOrderItemDocumentThumbnails(id, ownerId, retryCount - 1, retryDelay)
                                    .then(resolve)
                                    .catch(reject);
                            });
                    } else {
                        reject(new Error('The thumbnails uploading state is unhandled'));
                    }
                }).fail(reject);
            }
        });
    };
})(jQuery);

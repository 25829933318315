(function($) {
    $(function () {
        $('.customer-account-view-button').on('click', function() {
            $('.customer-account-view').removeClass('d-block').addClass('d-none');
            $('.customer-account').removeClass('d-none').addClass('d-block');
        });

        $('.customer-type-request-view-button').on('click', function() {
            $('.customer-type-request-view').removeClass('d-block').addClass('d-none');
            $('.customer-type-request').removeClass('d-none').addClass('d-block');
        });

        $('.customer-shipper-address-view-button').on('click', function() {
            $('.customer-shipper-address-view').removeClass('d-block').addClass('d-none');
            $('.customer-shipper-address').removeClass('d-none').addClass('d-block');
        });
        $('.shipper-choice input[type="radio"]').on('click', function() {
            var $this = $(this);

            $('.shipper-choice-content').hide();
            $($this.data('target')).show();
        }).filter('[checked]').click();

    });
})(jQuery);

